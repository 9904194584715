@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    input[class='form-input']:-webkit-autofill,
    input[class='form-input']:-webkit-autofill:hover,
    input[class='form-input']:-webkit-autofill:focus,
    textarea[class='form-input']:-webkit-autofill,
    textarea[class='form-input']:-webkit-autofill:hover,
    textarea[class='form-input']:-webkit-autofill:focus,
    select[class='form-input']:-webkit-autofill,
    select[class='form-input']:-webkit-autofill:hover,
    select[class='form-input']:-webkit-autofill:focus {
        @apply form-input;
    }

    input[class='form-input-icon']:-webkit-autofill,
    input[class='form-input-icon']:-webkit-autofill:hover,
    input[class='form-input-icon']:-webkit-autofill:focus,
    textarea[class='form-input-icon']:-webkit-autofill,
    textarea[class='form-input-icon']:-webkit-autofill:hover,
    textarea[class='form-input-icon']:-webkit-autofill:focus,
    select[class='form-input-icon']:-webkit-autofill,
    select[class='form-input-icon']:-webkit-autofill:hover,
    select[class='form-input-icon']:-webkit-autofill:focus {
        @apply form-input-icon;
    }

    input[class='form-input-line']:-webkit-autofill,
    input[class='form-input-line']:-webkit-autofill:hover,
    input[class='form-input-line']:-webkit-autofill:focus,
    textarea[class='form-input-line']:-webkit-autofill,
    textarea[class='form-input-line']:-webkit-autofill:hover,
    textarea[class='form-input-line']:-webkit-autofill:focus,
    select[class='form-input-line']:-webkit-autofill,
    select[class='form-input-line']:-webkit-autofill:hover,
    select[class='form-input-line']:-webkit-autofill:focus {
        @apply form-input-line;
    }
}

@layer utilities {

    .header-text-padding{
        @apply pt-10;
    }

    .form-group {
        @apply mb-5 space-y-1;
    }

    .form-group label {
        @apply block;
    }

    .form-input-required {
        @apply ml-1 text-red-500;
    }

    .form-input {
        @apply w-full h-10 px-4 py-1 text-sm border border-gray-300 rounded focus:outline-none focus:ring-2 focus:border-transparent;
    }

    .form-input[disabled] {
        @apply bg-gray-200 opacity-100;
    }

    .form-input-sm {
        @apply form-input;
        @apply h-7 text-sm !important;
    }


    .form-group-icon {
        @apply w-full text-sm rounded-xl ring-2 ring-transparent;
        @apply flex overflow-hidden;
    }

    /* .form-group-icon:focus-within {
        @apply border-transparent outline-none;
    } */

    .form-group-icon > label {
        @apply h-full flex flex-shrink-0;
    }

    /* .form-group-icon > label > .form-group-icon-attach{
        @apply m-auto;
    } */

    .form-input-icon {
        @apply border-none outline-none focus:border-transparent focus:outline-none focus:ring-0;
    }

    
    .form-group-icon-2 {
        @apply w-full text-sm rounded ring-2 ring-transparent;
        @apply flex overflow-hidden;
    }

    /* .form-group-icon-2:focus-within {
        @apply border-transparent outline-none;
    } */

    .form-group-icon-2 > label {
        @apply w-10 h-full flex flex-shrink-0;
    }

    .form-group-icon-2 > label > .form-group-icon-attach{
        @apply m-auto;
    }

    .form-input-icon-2 {
        @apply w-full h-full px-0 py-1 text-sm border-none outline-none focus:outline-none focus:ring-0;
        /* @apply w-full h-9 px-0 py-1 text-sm border-none outline-none focus:border-transparent focus:outline-none focus:ring-0; */
    }




    .form-input-line {
        @apply w-full h-10 px-4 py-1 text-sm bg-transparent border-t-0 border-l-0 border-r-0 border-b border-white focus:outline-none;
    }

    .form-input-line-referral {
        @apply form-input-line;
        @apply h-6 px-2;
    }

    .form-input-line-referral::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        @apply text-white opacity-50;
    }
        
    .form-input-line-referral:-ms-input-placeholder { /* Internet Explorer 10-11 */
        @apply text-white;
    }
        
    .form-input-line-referral::-ms-input-placeholder { /* Microsoft Edge */
        @apply text-white;
    }

    .placeholder-black::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #000;
        opacity: 1; /* Firefox */
    }
        
    .placeholder-black:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #000;
    }
        
    .placeholder-black::-ms-input-placeholder { /* Microsoft Edge */
        color: #000;
    }

    .btn {
        @apply w-max text-sm flex items-center justify-center space-x-2 rounded-[3px] px-4 py-3;
        @apply whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-opacity-75;
    }

    .btn[disabled] {
        @apply cursor-default;
        opacity: 0.7;
        filter: opacity(70);
    }

    .btn > * {
        @apply my-auto;
    }

    .btn-sm {
        @apply h-7 px-3 py-1 text-sm;
    }

    .btn-md {
        @apply h-9 px-4 text-sm font-normal;
    }

    .btn-lg {
        @apply px-8 py-3;
    }

    .btn-block {
        @apply w-full;
    }

    .btn-ep-primary {
        @apply text-white bg-ep-primary-500 focus:ring-green-700;
        @apply hover:text-black hover:bg-ep-yellow;
    }

    .btn-ep-blue {
        /* @apply text-white bg-ep-new-blue focus:ring-blue-600; */
        @apply text-white bg-ep-primary-500 focus:ring-green-700;
        @apply hover:text-black hover:bg-ep-yellow;
    }

    .btn-ep-yellow {
        @apply bg-ep-yellow focus:ring-yellow-200;
        @apply hover:text-white;
        @apply btn-hover-effect btn-hover-horizontal-effect btn-hover-horizontal-effect-yellow;
        /* @apply btn-hover-effect btn-hover-vertical-effect btn-hover-vertical-effect-yellow; */
    }

    .btn-transparent-black {
        @apply hover:text-white border border-gray-400 focus:ring-black;
        @apply btn-hover-effect btn-hover-horizontal-effect btn-hover-horizontal-effect-black;
        /* @apply btn-hover-effect btn-hover-vertical-effect btn-hover-vertical-effect-orange; */
        @apply focus:ring-gray-400;
    }

    .btn-transparent-blue {
        @apply text-ep-new-blue border border-ep-new-blue;
        @apply focus:ring-blue-600;
    }

    .btn-transparent-primary {
        @apply text-ep-primary border border-ep-primary-500;
        @apply focus:ring-green-700;
        @apply hover:text-white hover:bg-ep-primary-500;
    }

    .btn-hover-transparent-blue {
        @apply hover:text-white !important;
        @apply btn-hover-effect btn-hover-horizontal-effect btn-hover-horizontal-effect-transparent-blue;
    }

    .btn-hover-effect{
        -webkit-transition: background-position 0.5s;
        -moz-transition: background-position 0.5s;
        transition: background-position 0.5s;
    }

    .btn-hover-vertical-effect{
        background-size: 100% 200%;
    }

    .btn-hover-horizontal-effect{
        background-size: 200% 100%;
    }
    
    .btn-hover-vertical-effect:hover {
        background-position: 0 -100%;
    }

    .btn-hover-horizontal-effect:hover {
        background-position: -100% 0;
    }

    .btn-hover-vertical-effect-yellow{
        background-image: linear-gradient(to bottom, #E8C827 50%, #1638ce 50%);
    }

    .btn-hover-horizontal-effect-yellow{
        background-image: linear-gradient(to right, #E8C827 50%, #1638ce 50%);
    }

    .btn-hover-vertical-effect-black{
        background-image: linear-gradient(to bottom, transparent 50%, #000 50%);
    }

    .btn-hover-horizontal-effect-black{
        background-image: linear-gradient(to right, transparent 50%, #000 50%);
    }

    .btn-hover-vertical-effect-transparent-blue{
        background-image: linear-gradient(to bottom, transparent 50%, #1638ce 50%);
    }

    .btn-hover-horizontal-effect-transparent-blue{
        background-image: linear-gradient(to right, transparent 50%, #1638ce 50%);
    }





    .form-input-checkbox {
        /* @apply appearance-none text-ep-yellow bg-white border-2 border-gray-300 rounded cursor-pointer; */
        @apply appearance-none bg-white border-2 border-gray-300 rounded cursor-pointer;
    }

    .animate {
        @apply transform transition duration-500 ease-in-out;
    }

    .react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
        background: #fff;
    }

    .sidebar-links-divider {
        @apply mt-6 mb-1 opacity-75 text-xs uppercase px-6 text-white;
    }

    .sidebar-links {
        @apply h-10 pr-3 flex text-white text-sm font-semibold items-center cursor-pointer;
    }

    .sidebar-links-highlight {
        @apply w-0.5 h-6 mr-6 bg-white invisible;
    }

    .sidebar-links.active .sidebar-links-highlight {
        @apply visible;
    }

    .sidebar-links-icon {
        @apply mr-5 object-contain;
    }

    .dropdown {
        @apply z-10 bg-white absolute rounded shadow-lg overflow-hidden;
        min-width: 100px;
        max-width: 200px;
        /* width: 150px; */
        /* @apply py-0.5; */
    }

    .dropdown-left {
        @apply left-0;
    }

    .dropdown-right {
        @apply right-0;
    }

    .dropdown-item {
        @apply px-4 py-1.5 text-sm block border-t border-gray-100 cursor-pointer hover:text-white hover:bg-ep-primary-500;
    }

    .dropdown-item-large {
        @apply dropdown-item;
        @apply text-lg !important;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    .dropdown .dropdown-item:nth-child(1),
    .dropdown .dropdown-item-large:nth-child(1) {
        @apply border-t-0;
    }

    .box {
        @apply p-4 xl:p-6 bg-white rounded-lg shadow overflow-hidden;
    }

    .box-no-padding {
        @apply p-0 xl:p-0;
    }

    .label {
        @apply px-2 py-1 text-xs rounded whitespace-nowrap inline font-bold;
    }

    .label-blue {
        @apply text-blue-600 bg-blue-100;
    }

    .label-green {
        @apply text-green-600 bg-green-100;
    }

    .label-red {
        @apply text-red-600 bg-red-100;
    }

    .label-yellow {
        @apply text-yellow-600 bg-yellow-100;
    }

    .table-container {
        @apply w-full overflow-x-auto overflow-y-hidden;
    }

    .table-container > .table th,
    .table-container > .table td {
        @apply w-min whitespace-nowrap;
    }
    
    .table {
        @apply w-full min-w-min;
    }

    .table.table-border th,
    .table.table-border td {
        @apply p-4 border-2 border-gray-100;
    }

    .table thead {
        background-color: #fafafb;
    }

    .table th {
        @apply text-xs text-left font-semibold uppercase;
        color: #8492a6;
    }

    .table td {
        @apply text-sm text-left;
        /* @apply text-sm text-left align-top; */
    }

    .table td .dropdown {
        @apply right-2 -ml-3 md:-ml-4;
    }

    .table-info-full-page {
        @apply box;
        @apply py-24 mt-6;
    }

    .table-info {
        @apply px-2 py-20 text-center text-gray-500;
    }

    .table-info > svg {
        @apply text-4xl mb-2;
    }

    .pagination {
        @apply flex rounded space-x-0.5 overflow-hidden;
    }

    .pagination-items {
        @apply h-8 px-3 text-xs flex space-x-2 rounded-md cursor-pointer hover:bg-gray-200;
        @apply mr-1 !important;
    }

    .pagination-items-null {
        @apply h-8 px-2 text-xs flex space-x-2 rounded-md;
        @apply mr-px !important;
    }

    .pagination-items > div,
    .pagination-items-null > div{
        @apply my-auto;
    }

    .pagination-items-icon {
        @apply w-4 h-3 m-auto;
    }

    .pagination-items.disabled {
        @apply bg-gray-100 cursor-default hover:bg-gray-100;
    }

    .pagination-items-icon.disabled,
    .pagination-items-icon.disabled > div {
        @apply text-gray-300 group-hover:text-gray-300;
    }

    .pagination-items-active{
        @apply text-white bg-ep-blue hover:bg-ep-blue;
    }

    .tab-pill {
        @apply px-5 sm:px-10 py-1 m-auto text-sm rounded-lg hover:text-white hover:bg-blue-500;
    }

    .tab-pill-active {
        @apply bg-white shadow;
    }

    .ellipsis {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .ellipsis-2-lines,
    .ellipsis-two-lines,
    .ellipsis-line-2,
    .ellipsis-line-two,
    .ellipsis-lines-2,
    .ellipsis-lines-two {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .ellipsis-multiple,
    .ellipsis-multiple-lines{
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .ellipsis-multiple-2,
    .ellipsis-multiple-lines-2{
        -webkit-line-clamp: 2;
    }

    .ellipsis-multiple-3,
    .ellipsis-multiple-lines-3{
        -webkit-line-clamp: 3;
    }

    .ellipsis-multiple-4,
    .ellipsis-multiple-lines-4{
        -webkit-line-clamp: 4;
    }

    .ellipsis-multiple-5,
    .ellipsis-multiple-lines-5{
        -webkit-line-clamp: 5;
    }

    .ellipsis-multiple-6,
    .ellipsis-multiple-lines-6{
        -webkit-line-clamp: 6;
    }

    .text-fade{
        /* @apply text-gray-400; */
        color: #374151;
    }

    .tab {
        @apply py-3 mr-3 sm:mr-5 text-sm font-bold text-gray-500 hover:bg-blue-100;
    }

    .tab.active {
        @apply text-gray-600 border-b-2 border-blue-500;
    }
    
    .note-no-bg {
        @apply text-sm;
        /* padding: 5px 10px; */
        padding: 8px 15px;
        text-align: justify;
    }

    .note {
        @apply note-no-bg;
        background: #ffea77 !important;
    }

    .floated-content:after {
        display: block;
        content: "";
        clear: both;
    }

    .container{
        @apply mx-auto px-4 xl:px-10;
    }

    #headlessui-menu-items-2{
        outline: none !important;
    }

    #nav-icon {
        @apply w-6 h-9 m-auto relative cursor-pointer;
        /* margin-top: .25rem; */
        /* width: 45px; */
        /* height: 30px; */
        /* position: relative; */
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        /* cursor: pointer; */
    }
    
    #nav-icon span {
        width: 100%;
        height: 2px;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
        @apply block fixed left-0 opacity-100 rounded-md bg-ep-primary;
    }
    
    #nav-icon.nav-icon-white span {
        @apply bg-white !important;
    }
    
    #nav-icon span:first-child {
        top: 8px;
    }
    
    #nav-icon span:nth-child(2),
    #nav-icon span:nth-child(3) {
        top: 16px;
    }
    
    #nav-icon span:nth-child(4) {
        top: 24px;
    }
    
    #nav-icon.open span {
        --tw-bg-opacity: 1;
        background-color: #00535C;
    }
    
    #nav-icon.open span:first-child {
        top: 18px;
        width: 0;
        left: 50%
    }
    
    #nav-icon.open span:nth-child(2) {
        transform: rotate(45deg);
    }
    
    #nav-icon.open span:nth-child(3) {
        transform: rotate(-45deg);
    }
    
    #nav-icon.open span:nth-child(4) {
        top: 18px;
        width: 0;
        left: 50%
    }

    .section{
        padding: 1rem;
    }

    .sticky-top{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 2;
    }

    .search-highlight{
        @apply bg-yellow-200;
    }

}

body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
	display: none !important;
}

@media screen and (max-width: 1024px) {
    .earnipay-logo {
        transform: scale(0.8);
    }
}