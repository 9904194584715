@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap");
    @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700&display=swap');
    
    @font-face {
        font-family: "Boing";
        font-weight: 300;
        src: local("Boing"), url("../fonts/Boing/Boing-Light.ttf") format("truetype");
    }

    @font-face {
        font-family: "Boing";
        font-weight: 400;
        src: local("Boing"), url("../fonts/Boing/Boing-Regular.ttf") format("truetype");
    }

    @font-face {
        font-family: "Boing";
        font-weight: 500;
        src: local("Boing"), url("../fonts/Boing/Boing-Medium.ttf") format("truetype");
    }

    @font-face {
        font-family: "Boing";
        font-weight: 600;
        src: local("Boing"), url("../fonts/Boing/Boing-Semibold.ttf") format("truetype");
    }

    @font-face {
        font-family: "Boing";
        font-weight: 700;
        src: local("Boing"), url("../fonts/Boing/Boing-Bold.ttf") format("truetype");
    }

    @font-face {
        font-family: "Recoleta";
        font-weight: 400;
        src: local("Recoleta"), url("../fonts/Recoleta/Recoleta-RegularDEMO.ttf") format("truetype");
    }

    @font-face {
        font-family: "Recoleta";
        font-weight: 700;
        src: local("Recoleta"), url("../fonts/Recoleta/Recoleta-Bold.ttf") format("truetype");
    }

    @font-face {
        font-family: "Aeonik";
        font-weight: 400;
        src: local("Aeonik"), url("../fonts/Aeonik/Aeonik-Regular.ttf") format("truetype");
    }

    @font-face {
        font-family: "Aeonik";
        font-weight: 500;
        src: local("Aeonik"), url("../fonts/Aeonik/Aeonik-Medium.ttf") format("truetype");
    }

    @font-face {
        font-family: "Aeonik";
        font-weight: 700;
        src: local("Aeonik"), url("../fonts/Aeonik/Aeonik-Bold.ttf") format("truetype");
    }

    body {
        color: #111827;
        font-size: 18px;
        font-weight: medium;
        /* font-family: Main-Font,Helvetica,sans-serif; */
        /* font-family: "Boing", sans-serif; */
        /* font-family: "DM Sans", sans-serif; */
        font-family: "Aeonik";
        -webkit-font-smoothing: antialiased; /* Chrome, Safari, Opera */
        -moz-osx-font-smoothing: grayscale;  /* Firefox on macOS */  
    }
}

@layer utilities {
    .font-tiny {
        font-size: 10px;
    }

    .font-boing {
        font-family: "Boing";
    }

    .font-work-sans {
        font-family: "Work Sans", sans-serif;
    }

    .font-recoleta {
        font-family: "Recoleta";
    }

    .font-aeonik {
        font-family: "Aeonik";
    }

    .page-body, .page-container{
        /* min-height: calc(100vh-100px); */
        /* min-height: 100vh; */
        min-height: 500px;
    }

    .btn {
        @apply font-aeonik;
    }

    .bg-img:before {
        width: 100%; 
        height: 100%;  
        content: " ";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
    }

    .bg-img-under {
        width: 100%; 
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .bg-circle-under,
    .bg-circle:before {
        background-image: url("../images/circle-bg.svg");
        background-size: auto;
        background-repeat: no-repeat;
        opacity: 0.08;
    }
    
    @media (min-width: 768px) {
        .bg-hero-circle,
        .bg-hero-circle:before {
            background-image: url("../images/bgs/hero-circles.svg");
            background-size: auto;
            background-repeat: no-repeat;
            background-position: 120% center;
        }
    }
    
        .bg-hero-circle-full,
        .bg-hero-circle-full:before {
            background-image: url("../images/bgs/hero-circles-full.svg");
            /* background-size: 600px; */
            background-size: 75%;
            background-repeat: no-repeat;
            background-position: center center;
        }
    
    .bg-confetti-under,
    .bg-confetti:before {
        background-image: url("../images/main-landing/confetti.svg");
        background-repeat: no-repeat;
        /* background-position: cover; */
        background-position: 150px 0px;
        -webkit-animation: fade-in .7s ease-in-out both;
        animation: fade-in .7s ease-in-out both;
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
    }

    .bg-spiral-under,
    .bg-spiral:before {
        background-image: url("../images/spirals.svg");
        background-repeat: no-repeat;
        /* background-size: cover; */
    }

    .bg-spiral-2-under,
    .bg-spiral-2:before {
        background-image: url("../images/cta/2nd-spiral.svg");
        background-repeat: no-repeat;
        /* background-size: cover; */
    }

    .bg-spiral-3-under,
    .bg-spiral-3:before {
        background-image: url("../images/cta/3rd-spiral.svg");
        background-repeat: no-repeat;
        /* background-size: cover; */
    }

    .bg-wavy,
    .bg-wavy:before {
        background-image: url("../images/bgs/wavyBg.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .bg-curves,
    .bg-curves:before {
        background-image: url("../images/bgs/curves.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .bg-terrain,
    .bg-terrain:before {
        background-image: url("../images/bgs/terrain.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .bg-map-under,
    .bg-map:before {
        background-image: url("../images/testimonials/g14.svg");
        background-repeat: no-repeat;
        /* background-size: contain; */
        opacity: 0.2;
    }

    .auth-bg-ep-pattern {
        background-image: url("../images/bgs/auth-bg-ep-pattern.png");
    }

    .sidebar-bg-ep-pattern {
        background-image: url("../images/bgs/sidebar-bg-ep-pattern.png");
    }

    .auth-bg-ep-pattern-white {
        background-image: url("../images/bgs/auth-bg-ep-pattern-white.png");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .border-3{
        border-width: 3px;
    }

    .auth-frame-active{
        @apply border-ep-primary;
        background-image: url("../images/bgs/auth-bg-frame.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;
        box-shadow: 0px 10px 30px rgba(206, 178, 60, 0.2);
    }

    .form-input-line {
        @apply focus:border-ep-yellow;
    }

    .terms{
        text-align: justify;
        line-height: 30px;
    }

    .terms ul {
        list-style-type: disc;
    }

    .terms ul.circle {
        list-style-type: circle;
    }

    .terms ol {
        list-style-type: decimal;
    }

    .terms ol.upper-roman {
        list-style-type: upper-roman;
    }

    .terms ol.lower-roman {
        list-style-type: lower-roman;
    }

    .terms ol.upper-alpha {
        list-style-type: upper-alpha;
    }

    .terms ol.lower-alpha {
        list-style-type: lower-alpha;
    }

    .terms li {
        /* padding-right: 0.6em !important; */
        /* background-color: #f00; */
        margin-bottom: 1em;
    }

    .terms ol.parent-ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
    }
    
    .terms li.parent-li {
        display: table;
        counter-increment: item;
        /* margin-bottom: 0.6em; */
    }
    
    .terms li.parent-li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;    
    }
    
    .terms li.parent-li li.parent-li {
        margin: 0;
    }
    
    .terms li.parent-li li.parent-li:before {
        content: counters(item, ".") " ";
    }

    .terms-title{
        margin: 30px 0 10px;
        font-size: 16px;
        @apply font-medium;
    }

    /* .parent-ol .terms-title{
        padding-right: 0.6em;
    } */

    .nested-list {
        list-style-type: none; 
        counter-reset: item; 
    }

    .nested-list li {
        counter-increment: item; 
    }

    .nested-list li::before {
        content: counter(item) ". "; 
    }

    .page-title{
        @apply text-2xl sm:text-3xl md:text-4xl font-medium font-aeonik;
        @apply text-center max-w-5xl mx-auto;
    }

    .page-title > div:nth-child(2){
        @apply text-base font-normal mt-3 mx-auto;
    }

    .bg-banner-pattern{
        background-image:url("../images/bgs/banner-pattern.svg");
        background-position: center top;
    }

    .gradient-bg{
        background-image:url("../images/gradient-bg.svg");
        background-position: center bottom;
    }

    .gradient-confetti{
        background-image:url("../images/gradient-confetti.svg");
        background-position: top center;
        background-size: contain;
    }

    .bg-shapes{
        background-image:url("../images/shapes.svg");
        /* background-position: center bottom; */
        background-size: contain;
        /* background-size: 100% 100%; */
    }

    .bg-waves{
        background-image:url("../images/bgs/waves.png");
        background-position: center top;
        background-size: 100% 100%;
    }

    .bg-blur{
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
        /* background-color: rgba(255, 255, 255, 0.5); */
    }

    .header-links,
    .header-links-new{
        @apply py-4 flex;
        /* font-size: 16px; */
    }

    .header-links > span{
        @apply my-auto;
        @apply mt-2 pb-1 border-b border-transparent;
    }

    .header-links.active{
        /* @apply font-bold; */
        @apply underline;
    }

    /* .header-links-new.active{
        @apply text-ep-primary-600 font-semibold;
    } */

    .header-links.active > span{
        /* @apply text-ep-yellow; */
        @apply border-b border-black;
    }

    .header-links-dropdown{
        @apply bg-white border border-gray-200 absolute top-12 left-0 rounded overflow-hidden;
        @apply hidden;
        /* top: 52px; */
    }
    
    .header-links:hover .header-links-dropdown{
        @apply block;
    }

    .header-links-dropdown a{
        @apply px-4 py-3 block whitespace-nowrap;
    }

    .header-links-dropdown a:hover{
        @apply bg-[#f7fafb];
    }

    .header-links-mobile{
        @apply mx-auto py-4 text-center;
    }

    .header-links-mobile > *{
        @apply px-4 py-2 inline-block font-boing font-medium text-xl hover:text-ep-primary;
    }

    .header-links-social{
        @apply w-10 h-10 p-2 text-center flex hover:bg-ep-primary;
    }

    .header-links-social > img{
        @apply w-full h-full object-contain;
    }

    .fin-edu-category-links{
        @apply px-4 flex opacity-60 hover:opacity-100;
    }

    .fin-edu-category-links.active{
        @apply border-b-4 opacity-100;
    }

    .fin-edu-category-links > span{
        @apply my-auto whitespace-nowrap;
    }

    .footer-links{
        /* @apply font-semibold; */
        font-size: 16px;
    }

    .faq{
        @apply bg-white border rounded-md overflow-hidden;
    }

    .faq.active{
        @apply bg-gray-100 shadow-lg !important;
    }

    .faq > div:nth-child(2){
        @apply hidden;
    }

    .faq.active > div:nth-child(2){
        @apply block !important;
    }
    
    .faq-home{
        color: #6b7280;
    }
    
    .faq-home .faq-home-minus{
        @apply hidden !important;
    }

    .faq-home-active .faq-home-plus{
        @apply hidden !important;
    }

    .faq-home-active .faq-home-question{
        /* @apply text-ep-new-blue; */
        color: #1638ce !important;
    }

    .faq-home-active .faq-home-answer,
    .faq-home-active .faq-home-minus{
        @apply block !important;
    }

    @media (min-width: 768px) {        
        .react-slideshow-wrapper.slide {
            padding-bottom: 80px;
        }
        /* .react-slideshow-wrapper.slide > .images-wrap{
            @apply space-x-10;
        } */
    }

    .testimony-slide-click{
        /* background: #ff0; */
        position: absolute;
        z-index: 10;
        /* top: -100px; */
        top: -90px;
        cursor: pointer;
    }

    .testimony-slide-prev{
        right: 0;
    }
    
    .testimony-slide-next{
        right: 50px;
    }

    .slider-testimony{
        @apply absolute z-10 -bottom-16 bg-red-300;
        @apply w-10 h-10 px-0 bg-gray-200 rounded-full flex items-center justify-center cursor-pointer;
        

        @apply bg-ep-primary;
    }

    .slider-testimony:hover *{
        @apply text-white;
    }

    .slider-testimony-left{
        left: 50% !important;
        transform: translate(-20px) !important;
    }

    .slider-testimony-right{
        left: 50% !important;
        transform: translate(-20px) !important;
    }

    .tab-active{
        @apply opacity-100 font-bold;
        background-color: rgba(0, 103, 112, 0.08);
    }

    /* .typed-cursor {
    } */
    
    /* If fade out option is set */
    /* .typed-fade-out {
    } */

    @media (min-width: 1536px) {        
        .home-title-text-line-2{
            min-height: 160px;
        }
        .home-img-girl{
            max-height: 700px;
        }
    }
    
    @media (max-width: 1535px) {        
        .home-title-text-line-2{
            min-height: 90px;
        }
        .home-img-girl{
            max-height: 600px;
        }
    }

    @media (max-width: 640px) {        
        .home-title-text-line-2{
            min-height: 120px;
        }
    }

    .table-style-2 th,
    .faq-table-price th{
        @apply text-center;
    }

    .table-style-2 th,
    .table-style-2 td,
    .faq-table-price th,
    .faq-table-price td{
        @apply px-4 py-2 border border-gray-500;
    }

    .step-active{
        background: linear-gradient(270.3deg, rgba(0, 103, 112, 0.04) 69.92%, rgba(0, 103, 112, 0) 98.94%);
    }

    .shadow-015{
        --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.15);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .slick-track{
        display: flex !important;
    }

    .slick-slide{
        height: inherit !important;
    }

}

.faq-answer ul, .faq-answer ol{
    padding: 10px;
}

@media screen and (min-width: 768px) {
    .referer-svg-icon{
        height: 305px;
    }
    
}

.text-container {
    display: inline-block;
    height: 1.5em;
}
  
.animated-text {
    display: inline-block;
    animation: scroll-in 0.5s ease-in-out;
}
  
@keyframes scroll-in {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    
    50% {
        transform: translateY(10%);
        opacity: 1;
    }
    
    70% {
        transform: translateY(-20%);
    }
    
    90% {
        transform: translateY(10%);
    }
    
    100% {
        transform: translateY(0);
    }
}
  

/* hubspot form action butto */
 #hubspotForm .actions{
     display: flex;
     justify-content: flex-end;

 }

.header-wrapper {
    height: 85px;
    border-color: #0000000D;
    background: linear-gradient(180deg, #E5FCFF 0%, #FFFFFF 100%);
}

.header-main-wrapper {
    background-color: #E5FCFF;
    height: 93px;
    border-bottom: 1px solid #D1FBFF33;
}

@media screen and (min-width: 1500px) {
    .website-nav {
        max-width: 1171px;
    }
}

.website-nav {
    height: 77px;
    margin: 16px auto 0;
    width: 100%;
    max-width: 91%;
    display: flex;
    align-items: center;
}

.website-nav-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 52px;
    width: 100%;
}

.website-nav-inner a, .website-nav-inner span {
    font-size: 16px;
    line-height: 28px;
    color: #0A0D14;
    font-weight: 400;
}

.website-nav-link-active {
    background-color: #ABD9DC;
    border-radius: 24px;
}

.website-nav-link-active a, .website-nav-link-active span {
    color: #1A202C;
    font-weight: 500;
}

.logo-and-acct-type {
    display: flex;
}

.logo-wrapper {
    margin-right: 32px;
    display: flex;
    align-items: center;
}

.acct-type-wrapper {
    display: flex;
    align-items: center;
}

.acct-type-wrapper > li:first-child {
    margin-right: 24px;
}

.other-nav-menu-and-sign-in-btn > ul, .other-nav-menu-and-sign-in-btn > ul > li, .other-nav-menu-wrapper > li {
    display: flex;
    align-items: center;
}

.other-nav-menu-wrapper {
    display: flex;
    align-items: center;
    margin-right: 32px;
}

.other-nav-menu-wrapper > li:first-child {
    margin-right: 24px;
    width: 116px;
    height: 52px;
    padding: 12px 16px;
    cursor: pointer;
}

.acct-type-wrapper > li, .other-nav-menu-wrapper > li {
    height: 52px;
    padding: 12px 16px;
}

.other-nav-menu-wrapper > li:first-child span {
    display: inline-block;
    margin-right: 12px;
}

.nav-sign-in-btn {
    width: 111px;
    height: 41px;
    padding: 11px 20px;
    border-radius: 26px;
    border: 1px solid;
    border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.1904) 0%, rgba(255, 255, 255, 0) 100%);
    background-color: #006770;
    color: #ffffff;
    box-shadow: 0px 0px 0px 2px #00535C4F;
}

.nav-sign-in-btn > a {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-top: -2px;
}

.nav-sign-in-btn > a span {
    display: inline-block;
    margin-right: 6px;
    font-size: 16px;
    line-height: 19.2px;
    color: #ffffff;
}

.nav-sign-in-btn > a img {
    margin-top: 2px;
}

.hamburger-menu {
    display: none;
}

.general-layout-wrapper {
    margin-top: 93px;
}

.header-chevron-down-icon {
    width: 13px;
    height: 13px;
    margin: 3px 0 0 0.8rem !important;
}

.landing-page-header-gradient-full {
    background: linear-gradient(to bottom, transparent 1%, #E5FCFF 1%);
}

.page-header-gradient-half {
    background: linear-gradient(to top, transparent, #E5FCFF);
}
.page-header-gradient-halfs {
    background: linear-gradient(to top, transparent 50%, #E5FCFF);
}

.landing-page-header-gradient {
    background: linear-gradient(to bottom, transparent 0%, #E5FCFF 50%, transparent 100%);
}

.landing-page-laptop-img {
    transform: scale(0.9);
}

.earnipay-by-the-no-text {
    font-size: 24px;
    font-weight: 400;
    max-width: 80%;
    line-height: 28.8px;
}

.playstore-btn {
    background-color: #090909;
    color: #ffffff;
    border-radius: 10px;
    padding: 9px 15px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 185px;
    height: 62px;
}

.google-playstore-btn {
    margin-left: 15px;
}

.playstore-btn-icon {
    height: 2rem;
}

.playstore-text-wrapper {
    margin-left: 15px;
    margin-left: 15px;
    height: 100%;
    line-height: 20px;
    margin-top: -10px;
}

.playstore-btn-sub-text {
    font-size: 12.5px;
    letter-spacing: 0.5px;
    white-space: nowrap;
}

.playstore-btn-text {
    font-size: 24px;
    white-space: nowrap;
    letter-spacing: 0.5px;
}

.google-playstore-btn-sub-text {
    font-size: 11.5px;
    letter-spacing: 0.1px;
}

.google-playstore-btn-text {
    font-size: 20px;
    letter-spacing: 0.1px;
}

.green-grid-wrapper {
    width: 100%;
    padding: 0 180px 0 150px;
    background-repeat: no-repeat;
    height: 640px;
    background-size: cover;
    background-position: center;
}

.get-earnipay-landing-btn {
    padding: 8px 20px;
    border-radius: 26px;
    font-size: 15px;
    display: flex;
    align-items: center;
    background-color: #00535C;
    color: #ffffff;
}

.get-earnipay-landing-btn:hover, .sign-up-landing-btn:hover {
    background-color: #00535C;
    color: #ffffff;
}

.sign-up-landing-btn {
    font-size: 15px;
    display: flex;
    align-items: center;
    background-color: #00535C;
    border-radius: 68px;
    color: #ffffff;
    padding: 12px 24px;
}

.contact-sales-landing-link {
    color: #00535C;
    font-size: 15px;
}

.business-tool {
    width: 32%;
    margin-bottom: 25px;
}

.business-tools-header {
    height: 215px;
}

.business-tool-body {
    height: 120px;
}

.get-personal-title {
    font-size: 48px;
    line-height: 57.6px;
}

.app-stores-landing a {
    height: 50px;
}

.app-stores-landing a img {
    border-radius: 10px;
}

.ml-5 {
    margin-left: 20px;
}

.line-height-14 {
    line-height: 42px !important;
}

.interested-in-earnipay-title {
    line-height: 50px;
    font-size: 40px;
}

.get-personal-section {
    width: 100%;
    margin-bottom: 100px;
    display: flex;
    justify-content: space-between;
}

.get-personal-section-left-img-section {
    margin-left: 7%;
}

.get-personal-section-left-img-section-2 {
    margin-left: 5%;
}

.get-personal-text-section-left {
    margin-left: 15%;
}

.get-personal-text-section-right {
    margin-right: 45%;
}

.interested-in-earnipay-btn {
    color: #0D545B;
    background-color: #ffffff;
    border-radius: 68px;
    padding: 12px 25px;
    font-size: 16px;
    cursor: pointer;
}

.interested-in-earnipay-link {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-left: 40px;
}

.testimonial-landing-header-badge {
    background-color: #F2F4F7;
    color: #344054;
    border-radius: 32px;
    padding: 4px 10px;
    font-size: 15px;
}

.active-testimonial {
    height: 420px;
    justify-content: space-between;
}

.inactive-testimonial {
    height: 350px;
}

.testimonials-landing {
    padding-bottom: 10rem;
}

.testimonials-landing-quote-bg {
    position: absolute;
    top: -35px;
    left: 10%;
}

.testimonials-landing .slick-track {
    display: flex;
    align-items: center;
}

.testimonials-landing .slick-current {
    margin: 0 20px;
}

.testimonials-landing .slick-slide {
    background-color: #ffffff;
}

.testimonials-landing .slick-dots {
    bottom: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slick-dots li.testimonial-slider-dot {
    background-color: #D9D9D9;
    border-radius: 50%;
    width: 8px;
    height: 8px;
}

.slick-dots li.testimonial-slider-active-dot {
    background-color: #398D94;
    width: 12px;
    height: 12px;
}

.one-platform-for-all-title {
    font-size: 40px;
    line-height: 48px;
}

.one-platform-for-all-subtext {
    font-size: 17px;
    margin-top: 15px;
    color: #50545a;
}

.footer-logo {
    height: 1.6rem;
}

.footer-social-icon {
    width: 20px;
    height: 20px;
}

.ndpr-icon {
    width: 5rem;
    height: 5rem;
}

.footer-link {
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 400;
    color: #0A0D14;
}

.footer-link-title {
    font-size: 14px;
    line-height: 26px;
    padding-bottom: 0;
    color: #0A0D14;
    opacity: 0.65;
}

.footer-tail-section {
    display: flex;
    justify-content: center;
}

.bg-pattern-landing {
    height: 585px;
    width: 510px;
    background-size: 585px 100%;
}

.odd-bg-style {
    background-position: left;
}

.even-bg-style {
    background-position: right;
}

.security-features {
    background-color: #F6F6F6;
}

.interested-in-section-wrapper {
    max-height: 450px;
}

.one-platform-container > div {
    height: 450px;
}

/* .one-platform-wrapper {
    padding: 4.3rem;
} */


.one-app-container {
    justify-content: center;
}

.one-app-wrapper {
    width: 100%;
    max-width: 551px;
    height: 535px;
    border-radius: 16px;
    border: 0.5px solid #77737317;
    background-color: #F6F8FA;
    margin-right: 33px;
}

.one-app-wrapper-content {
    width: 463px;
    height: 266.8px;
    padding: 71.1px 46px;
}

.one-app-wrapper-texts {
    height: 150px;
}

.one-app-for-all-title {
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.01em;
    color: #0A0D14;
}

.one-app-for-all-subtext {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    color: #0A0D14;
    opacity: 0.7;
}

.one-app-store {
    margin-top: 53.17px;
}

.one-app-for-money-needs-img-wrapper {
    width: 100%;
    max-width: 508px;
    height: 535px;
    border-radius: 24px;
    display: flex;
    justify-content: flex-end;
}

.earnipay-and-cert-footer {
    display: flex;
    flex-direction: column;
}

.get-personal-list {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
}

.get-personal {
    display: flex;
    position: relative;
    margin-bottom: 150px;
    justify-content: space-between;
    align-items: center;
}

.get-personal:last-child {
    margin-bottom: 0;
}

.get-personal-right {
    flex-direction: row;
}

.get-personal-left {
    flex-direction: row-reverse;
}

.get-personal-left > div:nth-child(2) {
    margin-left: 35px;
}

.get-personal-right > div:nth-child(2) {
    margin-right: 35px;
}

.get-personal-text-wrapper {
    z-index: 2;
    width: 100%;
    max-width: 40%;
}

.get-personal-img-wrapper {
    z-index: 2;
}

.get-personal-title {
    font-weight: 500;
    font-size: 38px;
    line-height: 42px;
}

.get-personal-text {
    font-weight: 400;
    font-size: 19px;
    margin-top: 5px;
}

.get-personal-pattern {
    position: absolute;
    width: 25%;
}

.get-personal-pattern-right {
    right: 0;
}

.get-personal-pattern-left {
    left: 0;
}

.bg-line-vector-header {
    position: absolute;
    z-index: 100;
    pointer-events: none;
    top: 0;
}

.nav-green-bg {
    background: linear-gradient(180deg, #e5fcff 0%, #ffffff 100%);
}

.header-signup-btn {
    outline: none;
    color: #ffffff;
    border: 2.5px solid #96c1c6;
    background-color: #00535C;
    display: flex;
    align-items: center;
    border-radius: 26px;
    padding: 9px 22px;
    font-size: 15px;
}

.header-signup-btn span {
    display: inline-block;
    margin-right: 6px;
}

.header-signup-btn svg {
    margin-top: 2px;
}

.green-btn-outline{
    border: #69989D 2px solid;
    background-color: linear-gradient(90deg, #1B636A, #01545D);
}

.rewards-green-card{
    border-radius: 1rem;
    border-width: 1px;
    border-color: #3F787D;
    background-color: #28676D;
    height: 160px;
    max-width: 428px;    
}

.company-cards-text{
    height: 50px;
    margin-bottom: 50px;
}

.company-cards{
    border-radius: 0.75rem;
    width: 100%;
}

.vector-header {
    z-index: -100;
    pointer-events: none;
    position: absolute;
    bottom: 63px;
}

.blur-effect{
    backdrop-filter: blur(3px);

}

.hero-main-text{
    font-size: 60px;
    color: #093D42;
    font-weight: 500;
    line-height: 72px;
    max-width: 850px;
    margin-top: 5rem;
}

.hero-sub-text{
    font-size: 20px;
    color: #484C4D;
    line-height: 24px;
}

.text-underline{
    text-underline-offset: 5px;
    font-weight: 500;
}

.payroll-main-img{
    height: 455px;
    width: 90%;
}

.get-started-button{
    color: white;
    background-color: #1A626A;
    border-color: #69989D;
    border-width: 1.5px;
}

.payments-cards-container{
    width: 100%;
}

.company-hero-img{
    background: linear-gradient(to bottom, #F6FDFE, white);;
}

.orange-diamond-subtext{
    color: #6C6E72;
    padding-top: 21px;
}

.personal-loan-apply-btn{
    color: white;
    background-color: #02545D;
    border-color: #69989D;
    border-width: 1.5px;
}

.bg-wavy-line-vector{
    position: absolute;
    z-index: -1;
    pointer-events: none;
    top: -35px;
}

.rewards-hero-coins{
    /* z-index: -10; */
    height: auto;
    max-width: 100%;
}

.odp-bg-wavy-line-vector{
    position: absolute;
    z-index: -1;
    pointer-events: none;
    bottom: 65px;
}

.footer-social-icon img {
    height: 100%;
}

@media screen and (min-width: 768px) and (max-width: 1440px){
    .hero-main-text{
        font-size: 48px;
        line-height: 60px;
    }

}

@media screen and (min-width: 413px) and (max-width: 415px){
    .company-cards-text{
        margin-bottom: 0px;
        padding: 10px;
    }
}

@media screen and (min-width: 415px) and (max-width: 767px) {
    .company-cards-text {
        margin-bottom: 0px;
    }
}
@media screen and (min-width: 414px) and (max-width: 767px) {
    .company-cards {
        width: 374px;
        height: 308px;
    }
}
@media screen and (min-width: 1023px) and (max-width: 1169px) {
    .savings-antenna-images{
        left: 1%;
        height: 49%;
        bottom: 0;
    }
    .company-cards-container-top{
        width: 945px;
    }

    .company-cards-container-bottom{
        width: 620px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .savings-antenna-images{
        right: 0;
        height: 30%;
        bottom: 50%;
    }
}


@media screen and (min-width: 1170px) {
    .savings-antenna-images{
        bottom: 0;
    }
}
@media screen and (min-width: 1300px) {
    .payroll-main-img{
        height: 510px;
    }
}

@media screen and (min-width: 1520px) {
    .payroll-main-img{
        height: 670px;
    }
}

@media screen and (min-width: 2000px) {
    .payroll-main-img{
        height: 845px;
    }
}

@media screen and (min-width: 2500px) {
    .payroll-main-img{
        height: 855px;
    }
}

@media screen and (max-width:1100px) {
    .payroll-main-img{
        height: 400px;
    }
}

@media screen and (min-width: 768px) {
    .green-grid-section-title-line-height {
        line-height: 76px;
        font-size: 59px;
    }

    .manage-your-business-title {
        line-height: 57px;
        font-size: 48px;
    }
}

@media screen and (max-width: 1200px) {
    .get-personal-text-wrapper {
        max-width: 50%;
    }

    .get-personal-left > div:nth-child(2) {
        margin-left: 45px;
    }
    
    .get-personal-right > div:nth-child(2) {
        margin-right: -1%;
    }

    .interested-in-section-wrapper {
        max-height: 100%;
    }

    .interested-in-earnipay-title {
        text-align: center;
    }

    .interested-in-section > div:first-child > div > div {
        flex-direction: column;
    }

    .interested-in-section > div:first-child > div > div > div {
        width: 250px;
        height: 50px;
    }

    .interested-in-section > div:first-child > div > div > div:nth-child(2) {
        margin-top: 20px;
        margin-left: 0;
        background-color: #306d73;
        border-radius: 68px;
        padding: 12px 25px;
        font-size: 16px;
        cursor: pointer;
        text-decoration: none;
        text-align: center;
    }

    .one-platform-for-all-title {
        display: flex;
        white-space: nowrap;
        font-size: 28px;
        line-height: 32px;
    }

    .one-platform-store {
        width: 100%;
    }
    
    .one-platform-container {
        flex-direction: column;
        padding: 0;
    }

    .one-platform-img {
        width: 100%;
    }

    .one-platform-container > div {
        width: 100%;
        margin: auto !important;
    }

    .one-platform-container > .one-platform-wrapper {
        padding: 2.5rem;
        margin-bottom: 30px !important;
    }

    .one-app-for-money-needs-img-wrapper {
        justify-content: flex-start;
    }

    .one-app-for-money-needs-img {
        width: 100%;
    }
}

@media screen and (max-width: 1150px) {
    .earnipay-numbers-label {
        width: 100%;
    }

    .earnipay-numbers-label > div {
        width: 100%;
    }

    .earnipay-numbers-label > div > div {
        width: 100%;
        max-width: 100%;
    }

    .earnipay-numbers-label > div > div:first-child {
        width: 100%;
        max-width: 100%;
        font-size: 40px;
        text-align: center;
    }

    .earnipay-numbers-label > div > div:nth-child(2) {
        max-width: 75%;
        margin: auto;
        text-align: center;
        padding: 0;
        font-size: 21px;
    }

    .earnipay-by-the-number-section {
        flex-direction: column;
        justify-content: space-evenly;
    }

    .earnipay-numbers {
        justify-content: center;
        width: 100%;
    }

    .earnipay-numbers > div {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

    .earnipay-numbers > div > div {
        height: fit-content;
        margin: 0 !important;
    }

    .earnipay-numbers > div > div > div:first-child {
        font-size: 40px;
        text-align: center;
    }

    .earnipay-numbers > div > div > div:nth-child(2) {
        font-size: 21px;
        padding: 0;
        margin-top: -15px;
    }

    .business-tools-wrapper {
        justify-content: space-evenly;
    }

    .business-tools-wrapper > div {
        width: 45%;
    }

    .security-features {
        flex-direction: column;
    }

    .savings-antenna-image{
        right: 65%;
        bottom: 0%;
    }
}

@media screen and (max-width: 1024px) {
    .bg-line-vector-header {
        display: none;
    }

    .footer-socials-wrapper {
        position: absolute;    
    }

    .get-personal-list {
        margin-top: 110px;
    }

    .get-personal {
        flex-direction: column-reverse;
    }

    .get-personal-text-wrapper {
        max-width: 100%;
        margin-bottom: 80px;
    }

    .get-personal-title {
        width: 80%;
        margin: auto;
    }

    .get-personal-title, .get-personal-text {
        text-align: center;
    }

    .get-personal-right > div:nth-child(2) {
        margin-right: 0;
    }

    .get-personal-left > div:nth-child(2) {
        margin-left: 0;
    }

    .get-personal-pattern {
        width: 45%;
        height: 630px;
        bottom: -50px;
    }

    .earnipay-and-cert-footer {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }

    .footer-socials-wrapper {
        top: 60px;
        left: 0;
    }

    .footer-socials-wrapper > div {
        width: 100%;
        max-width: 220px;
        margin: auto;
        display: flex;
        justify-content: space-between;
    }

    .footer-socials-wrapper > div > a {
        margin: 0;
    }

    .savings-antenna-images{
        left: 5% !important;
        height: 50%;
        bottom: 0;
    }   

    .bg-wavy-line-vector{
        display: none;
    }

    .footer-logo {
        margin-bottom: 30px;
    }

    .one-app-container {
        flex-direction: column;
    }

    .one-app-wrapper {
        margin-right: 0;
        max-width: 70%;
    }

    .one-app-wrapper-content {
        height: fit-content;
    }

    .one-app-for-money-needs-img-wrapper {
        max-width: 70%;
        margin-top: 36.22px;
    }
}

@media screen and (max-width: 768px) {
    .multiple-payments-container .multiple-payments{
        font-size: 25px;
        width: 100%;
    }

    .multiple-payments-content{
        font-size: 15px;
    }
    .multiple-payments-checklist{
        font-size: 15px;
    }
    .multiple-payments-btn{
        height: 35px;
    }
}

    .security-title {
        font-size: 28px;
    }

    .testimonials-landing-quote-bg {
        left: 15%;
        top: -110px;
        z-index: 2;
        width: 6rem;
    }

    .testimonial-landing-header-badge {
        padding: 10px 15px;
        font-size: 16px;
    }

    .customers-saying {
        font-size: 26px;
    }

    .testimony-wrap {
        padding-top: 80px;
    }

    .testimonials-landing .slick-slider {
        max-width: 70%;
    }

    /* .savings-antenna-images{
        right: 0;
        bottom: 50%;
    } */

@media screen and (max-width: 980px) {
    .green-grid-wrapper {
        padding: 0 50px;
    }

    .security-title {
        font-size: 28px;
    }

    .testimonials-landing-quote-bg {
        left: 15%;
        top: -110px;
        z-index: 2;
        width: 6rem;
    }

    .testimonial-landing-header-badge {
        padding: 10px 15px;
        font-size: 16px;
    }

    .customers-saying {
        font-size: 26px;
    }

    .testimony-wrap {
        padding-top: 80px;
    }

    .testimonials-landing .slick-slider {
        max-width: 70%;
    }

    .savings-antenna-image{
        right: 50%;
        bottom: 0%;
    }
}

@media screen and (max-width: 900px) {
    .acct-type-wrapper, .other-nav-menu-and-sign-in-btn ul {
        display: none;
    }

    .hamburger-menu {
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .page-body {
        margin-top: 2.5rem;
    }

    /* .page-container {
        margin-top: -2.5rem;
    } */

    .general-layout-wrapper {
        margin-top: 70px;
    }

    .header-main-wrapper {
        height: 70px;
        display: flex;
        align-items: center;
    }

    .website-nav {
        margin-top: 0;
        height: 52px;
    }

    .website-nav-inner {
        height: 32px;
    }

    .get-personal-pattern {
        height: 520px;
    }

    .get-personal-title {
        width: 70%;
    }

    .powering-business-title {
        font-size: 24px !important;
        line-height: 32px;
    }

    .access-high-yields {
        font-size: 15px !important;
        width: 48%;
        margin: 10px auto 0 auto;
    }

    .money-that-works-sub-text {
        width: 70%;
        margin: auto;
        font-size: 15px;
    }

    .green-grid-wrapper {
        height: 300px;
    }

    .earnipay-numbers-label > div > div:nth-child(2) {
        font-size: 15px;
        width: 430px;
        line-height: 20px;
        margin-top: 5px;
    }

    .earnipay-numbers-label > div > div:first-child {
        font-size: 20px;
    }

    .earnipay-numbers > div > div > div:first-child {
        font-size: 20px;
        width: fit-content;
    }

    .earnipay-numbers > div > div > div:nth-child(2) {
        font-size: 15px;
        margin-top: 0;
    }

    .manage-your-business-title {
        width: 350px;
        margin: 0 auto 20px;
    }

    .business-tools-wrapper > div {
        width: 90%;
        margin: 0 auto 30px auto;
    }

    .landing-page-signup {
        flex-direction: column;
    }

    .landing-page-signup > div {
        width: 173px;
    }

    .contact-sales-landing-link {
        margin: 20px 0 0 0 !important;
        border: 1px solid #00535C;
    }

    .get-personal-title {
        font-size: 25px;
        line-height: 30px;
        margin-top: 40px;
    }

    .playstore-btn {
        width: 158px;
        height: 58px;
    }

    .playstore-btn-sub-text {
        font-size: 11px;
    }

    .playstore-btn-text {
        font-size: 18px;
    }

    .playstore-text-wrapper {
        margin-left: 10px;
    }

    .bg-pattern-landing {
        height: 570px;
        background-size: 300px 100%;     
        width: 100%;
        background-repeat: no-repeat;
    }

    .testimonials-landing-quote-bg {
        left: 5%;
    }

    .one-platform-container > div {
        width: 100%;
    }

    .earnipay-and-cert-footer {
        justify-content: flex-start;
    }

    .earnipay-and-cert-footer > div:nth-child(2) {
        margin-left: 30%;
    }

    .savings-antenna-image{
        right: 50%;
        bottom: 0%;
    }

    .interested-in-earnipay-title {
        font-size: 20px;
        line-height: 24px;
    }
 
    .interested-in-section {
        flex-direction: row;
    }
    
    .multiple-payments-container{
        max-width: 80%;
    }

    .one-app-wrapper-content {
        padding: 62.79px 15px;
        width: 100%;
    }

    .one-app-wrapper-texts {
        width: 315px;
        height: 85.05px;
    }

    .one-app-for-all-title {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.01em;
    }

    .one-app-for-all-subtext {
        margin-top: 9.05px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.4px;
    }

    .one-app-store {
        margin-top: 30.09px;
        justify-content: flex-start;
        width: fit-content;
    }

    .one-app-store .playstore-btn {
        height: 50.28px;
    }

    .one-app-store .playstore-btn .playstore-btn-sub-text {
        font-size: 10px;
    }

    .one-app-store .playstore-btn .playstore-btn-text {
        font-size: 16px;
    }

    .one-app-store .playstore-btn .playstore-text-wrapper {
        line-height: 16px;
    }
}

@media screen and (max-width: 600px) {
    .green-grid-wrapper {
        padding: 0 20px;
    }


    .testimonials-landing .slick-slider {
        max-width: 90%;
    }

    .savings-antenna-image{
        right: 10%;
        bottom: 45%;
    }

    .interested-in-section {
        flex-direction: column-reverse;
    }

    .one-app-wrapper, .one-app-for-money-needs-img-wrapper {
        max-width: 80%;
    }
}

@media screen and (max-width: 540px) {
    .one-app-wrapper, .one-app-for-money-needs-img-wrapper {
        max-width: 95%;
    }
}

@media screen and (max-width: 500px) {
    .earnipay-and-cert-footer > div:nth-child(2) {
        margin-left: 20%;
    }

    .playstore-btn-text {
        font-size: 15px;
    }

    .landing-page-header-gradient {
        background: transparent;
    }

    .playstore-btn-icon {
        height: 1.3rem;
    }

    .playstore-btn {
        height: 52px;
        padding: 5px 10px;
    }
    
    .money-that-works-sub-text {
        padding: 5px 0 0 0;
    }

    .yield-earned-img {
        margin-top: -10px;
    }

    .leading-business-wrapper {
        margin-top: 0;
    }

    .leading-business-wrapper > div {
        margin-top: 2.3rem;
    }

    .leading-business-wrapper > div > div:nth-child(2) {
        margin-top: 0;
    }

    .leading-business {
        font-size: 15px;
    }

    .earnipay-numbers-label > div > div:nth-child(2) {
        font-size: 12px;
        font-weight: 400;
        line-height: 14.5px;
        max-width: 100%;
    }

    

    .earnipay-numbers > div > div > div:nth-child(2) {
        margin-top: 5px;
        font-size: 12px;
        text-align: center;
    }

    .manage-your-business-title {
        line-height: 23px;
        margin-top: 30px;
    }

    .get-earnipay-landing-btn {
        padding: 12px 45px;
        font-size: 13px;
        font-weight: 400;
    }

    .business-tools-header, .business-tool-body {
        height: 100%;
    }

    .landing-page-signup > div {
        font-size: 12px;
        justify-content: center;
    }

    .contact-sales-landing-link {
        border-width: 0.5px;
    }

    .get-personal-title {
        font-size: 20px;
        line-height: 23px;
    }

    .get-personal-text-wrapper .get-personal-title {
        font-size: 16px;
    }

    .get-personal-text {
        font-size: 12px;
    }

    .get-personal-list {
        margin-top: 20px;
    }

    .get-personal-img-wrapper {
        display: flex;
        justify-content: center;
    }

    .get-personal-img-wrapper img {
        width: 50%;
    }

    .get-personal-pattern {
        bottom: 0;
        height: 200px;
    }

    .get-personal-right > div:nth-child(2) {
        margin-bottom: 50px;
    }

    .get-personal {
        margin-bottom: 20px;
    }

    .security-title-wrapper {
        margin-top: 0;
    }

    .security-title {
        font-size: 20px;
    }

    .security-feature-img-wrapper {
        width: 24px;
        height: 24px;
        margin-top: 5px;
    }

    .security-feature-title {
        font-size: 14px;
    }

    .security-feature-text {
        font-size: 12px;
        line-height: 14px;
        margin-top: 2px;
    }

    .security-features {
        padding: 0;
        border-radius: 10px;
    }

    .security-features > div {
        padding: 15px;
    }

    .interested-in-section-wrapper {
        border-radius: 10px;
        background-position: initial !important;
    }

    .interested-in-section > div > div {
        padding: 1.5rem 0 1rem 0;
    }

    .interested-in-earnipay-title {
        font-size: 20px;
        line-height: 24px;
    }

    .interested-in-section > div:first-child > div > div {
        margin-top: 20px;
    }

    .interested-in-section > div:first-child > div > div > div {
        height: 45px;
    }

    .interested-in-section > div:first-child > div > div > div:nth-child(2) {
        margin-top: 10px;
    }

    .interested-in-earnipay-btn > a {
        height: fit-content;
        font-size: 12px;
        font-weight: 500;
    }

    .interested-in-earnipay-btn, .interested-in-earnipay-link > a {
        font-size: 13px;
        display: flex;
        justify-content: center;
    }

    .testimonial-landing-header-badge-wrapper {
        padding-bottom: 10px;
    }

    .testimonial-landing-header-badge {
        font-size: 12px;
        padding: 5px 10px;
    }

    .customers-saying {
        font-size: 20px;
        width: 90%;
        line-height: 24px;
    }

    .testimony-wrap {
        margin-top: 0;
        padding-top: 50px;
    }

    .testimonials-landing-quote-bg {
        width: 3rem;
        top: -85px;
    }

    .inactive-testimonial {
        height: 180px;
        padding: 15px;
        border-radius: 10px;
    }

    .active-testimonial {
        height: 250px;
        padding: 15px;
        border-radius: 10px;
    }

    .active-testimonial > div:first-child {
        color: #475467;
        font-size: 12px;
        line-height: 21px;
    }

    .active-testimonial > div:nth-child(2) img {
        width: 25px;
        height: 25px;
    }

    .active-testimonial > div:nth-child(3) {
        margin-top: 1rem;
    }

    .active-testimonial > div:nth-child(3) > div:first-child {
        font-size: 12px;
        line-height: 14.4px;
        color: #0A0D14;
    }

    .active-testimonial > div:nth-child(3) > div:nth-child(2) {
        font-size: 10px;
        line-height: 12px;
        color: #164564;
    }

    .testimonials-landing .slick-current {
        margin: 0;
    }

    .testimonials-landing .slick-dots {
        bottom: -45px;
    }

    .testimonials-landing {
        padding-bottom: 4rem;
    }

    .one-platform-container {
        width: 100%;
        max-width: 90%;
        margin: auto;
    }

    .one-platform-for-all-title {
        font-size: 20px;
        line-height: 24px;
    }

    .one-platform-for-all-subtext {
        font-size: 12px;
        line-height: 14.4px;
        margin-top: 5px;
    }

    .one-platform-container > .one-platform-wrapper {
        padding: 3.5rem 2rem;
    }

    .playstore-btn {
        width: fit-content;
        transform: scale(0.8);
    }

    .one-platform-store {
        margin-top: 2rem;
    }

    .google-playstore-btn {
        margin-left: 0;
    }

    .one-platform-store > div {
        margin-left: -35px;
    }

    /* .ndpr-icon {
        height: 96px;
        width: 100%;
    } */

    .create-business-acct {
        width: fit-content;
        height: 32px;
    }

    .create-business-inner-div {
        top: -2px;
        left: 0;
    }

    .create-business-acct a {
        display: inline-block;
        margin-left: 20px;
        font-size: 9px;
    }

    .access-high-yields {
        width: 90%;
        font-size: 13px !important;
        line-height: 16px;
    }

    .first-landing-playstore-btns {
        margin-top: 5px;
    }

    .first-landing-playstore-btns > div > a:nth-child(2) {
        margin-left: -20px;
    }

    .earnipay-logo {
        transform: scale(1);
    }

    .business-tools-wrapper > div {
        margin-bottom: 18px;
    }

    .get-personal-playstore-btns {
        margin-top: 10px;
    }

    .get-personal-playstore-btns > div > a:nth-child(2) {
        margin-left: -20px;
    }

    .get-personal-title-wrapper {
        margin-top: 20px;
    }

    .savings-antenna-image{
        right: 10%;
        bottom: 45%;
    }

    .payroll-main-img{
        height: 300px;
    }
}

@media screen and (max-width: 450px) {
    .earnipay-numbers > div > div {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .savings-antenna-image{
        right: 10%;
        bottom: 45%;
    }

    .multiple-payments-container{
        max-width: 80%;
    }

    .payroll-main-img{
        height: 200px;
    }

    .one-app-wrapper, .one-app-for-money-needs-img-wrapper {
        max-width: 100%;
    }
}

@media screen and (max-width: 420px) {
    .one-app-wrapper-texts {
        width: fit-content;
    }

    .one-app-store {
        margin-left: -15px;
    }
    .google-playstore-btn {
        margin-left: -20px;
    }
}

@media screen and (max-width: 320px){
    .payroll-main-img{
        height: 120px;
    }
}

@media screen and (max-width: 375px){
    .payroll-main-img{
        height: 150px;
    }
}

@media screen and (max-width: 520px){
    .rewards-hero-coins{
        height: 35%;
    }

}

@media screen and (max-width: 1280px) and (min-width: 1024px){
    .multiple-payments{
        font-size: 40px;
    }

    .business-payments-card-1{
        margin-top: -60px;
    }
}


@media screen and (min-width: 1023) and (max-width: 1025px) {
    .personal-hero-odp-ellipse-2{
      width: 705px;
    }
  }